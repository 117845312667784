/* eslint-disable */
import React, { useEffect, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import
{
  Card,
  Grid,
  Box,
  // Container,
  // InputAdornment,
  // Paper
} from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
// import * as Yup from 'yup';
// import { Formik, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import { findDOMNode } from 'react-dom';
import Countdown from 'react-countdown';
// import screenfull from 'screenfull';
// import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
import ReactPlayer from 'react-player';
// import GridContainer from "src/components/Grid/GridContainer.js";
// import GridItem from "src/components/Grid/GridItem.js";
import PropTypes from 'prop-types';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useParams, useSearchParams } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
// import { FixedSizeList } from 'react-window';
import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
import inLocalStorageJwt from 'src/utils/inLocalStorageJwt';
import RegisterView from 'src/views/auth/RegisterView';
import LoginView from 'src/views/auth/LoginView';
import Guest from 'src/views/auth/Guest';
import UserContext from 'src/contexts/UserContext';
import ApiCall from 'src/utils/ApiCall';
import moment from 'moment';
// import { io } from "socket.io-client";
import ParticipantList from './ParticipantList.js';
import TrainingContext from 'src/contexts/TrainingContext';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PageVisibility from 'react-page-visibility';
// import Wifi from 'src/components/Network/wifiSpeed';
import Soundbar from 'src/components/Controls/Soundbar';
import { useSocket } from 'src/contexts/SocketContext';

// const socket = io.connect(process.env.REACT_APP_SERVER_BASE_URL);
const useStateRef = require('react-usestateref');

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  videoCard: {
    maxWidth: true,
    width: '100%'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  customControls:{
    color:'white !important'
  },
  rightlist: {
    width: '100%',
    height: '100%',
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper,
  },
}));
function renderRow(props) {
    const { index, style } = props;
  
    return (
      <ListItem button style={style} key={index}>
        <ListItemText primary={`Item ${index + 1}`} />
      </ListItem>
    );
  }
renderRow.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
  };
export default function Training() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [planExpired, setPlanExpired] = React.useState(false);
  const [linkExpired, setLinkExpired] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [isVerifyLoading, setisVerifyLoading] = React.useState(false);
  const [showRegister, SetShowRegister] = React.useState(false);
  const [showLogin, SetShowLogin] = React.useState(false);
  const location = useLocation();
  const AuthTokenObject = inLocalStorageJwt.getAuthToken();
  const AuthToken = AuthTokenObject.authToken;
  const getLoggedinUserData = inLocalStorageJwt.getLoggedinUserData();
  const [isprivate, setPrivate] = React.useState(false);
  const [phoneVerify, setPhoneVerify] = React.useState(true);
  const [screenWidth, SetScreenWidth] = React.useState(window.innerWidth);
  const userContext = useContext(UserContext);
  const playerRef = React.createRef();
  const [playerObj, setPlayerObj] = React.useState(null);
  // const [socket, SetSocket] = useState();
  const [isWindowInFocus, setIsWindowInFocus] = useState(true);
  const countRef = React.createRef();
  const [premiereData, SetPremiereData] = useStateRef({});
  const trainingContext = useContext(TrainingContext);
  const [isPremListFull, SetisPremListFull] = useStateRef(false);
  const [subscriptionData, SetSubscriptionData] = useStateRef({});
  const [participantsLmt, SetparticipantsLmt] = useStateRef();
  const secretKey = process.env.REACT_APP_ENC_SECRET_KEY;
  const socket = useSocket();
  /*Creating the socket for participants*/
  // const socket = io(process.env.REACT_APP_API_BASE_URL);

  let forwardseek = false;
  const [searchParams, setSearchParams] = useSearchParams();
  const [seekCheck, setSeekCheck] = useState({
    pause: false,
    buffer: false,
    play: false
  });
  const [playingStatus, SetPlayingStatus] = useState({
    lastPlayed: 0,
    currentPlaying: 0
  });
  const [playingPath, SetPlayingPath] = useState([]);
  const [seeking, setSeeking] = useState();
  const [reactControl, SetReactControl] = useState({
    url: null,
    pip: false,
    playing: false,
    controls: false,
    light: false,
    volume: 0.8,
    muted: true,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false
  });
  // volume bar change
  const volumebar = (newValue) => {
    SetReactControl({...reactControl, volume:newValue/100});
  }
  const handleMute = () => {
    SetReactControl({...reactControl, muted: !reactControl.muted });
  }

  const seekToSeconds = (seconds) => {
    playerObj.seekTo(seconds, 'seconds');
  }
  /* Code for premiere timer */
  const [premPlay, setPremPlay] = useState(false);
  const [publnkPlay, setPublnkPlay] = useState(false);

  const [expMsg, setExpMsg] = useState('');
  // const Completionist = () => <span>Your Premiere is Expired!</span>;
  // const Completionist = () => {
  //   // setPremPlay(true);
  //   // console.log(premPlay);
  //   return true;
  // };

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      //return <Completionist />;
      return <span>{expMsg}</span>;
    } else {
      // Render a countdown
      return (
        <div style={{textAlign: 'center'}}>
          <h1>Session will start in</h1>
          {days>0 && (<><center><h2><b>{days}</b> Days</h2></center><br/></>)}
          <h3>
            {hours>0 && (<><b>{hours}</b>  Hrs: </>)}
            {minutes>0 && (<><b>{minutes}</b> Min: </>)}
            {seconds>0 && (<><b>{seconds}</b> Sec </>)}
          </h3>
        </div>
      );
    }
  };

  /**
   * starting tracking code
   */
  function format (seconds) {
    const date = new Date(seconds * 1000)
    const hh = date.getUTCHours()
    const mm = date.getUTCMinutes()
    const ss = pad(date.getUTCSeconds())
    if (hh) {
      return `${hh}:${pad(mm)}:${ss}`
    }
    return `${mm}:${ss}`
  }
  
  function pad (string) {
    return ('0' + string).slice(-2)
  }
  
  const handlePlay = async () => {
    // console.log('onPlay', premPlay);
    ApiCall.createViewer('Training',trainingData.id, getLoggedinUserData.id);
    // const timer = setTimeout(() => {
      // if(playerRef && playerRef.current && playerRef.current.getCurrentTime()){
        let object = `${trainingData.name} is played from ${format(playerRef.current.getCurrentTime())} / ${format(playerRef.current.getDuration())}`;
        // object += ` (video is )${playerRef.current.props.muted? 'muted': 'audible'}`;
        const play_pause_time = format(playerRef.current.getCurrentTime()) + '/' + format(playerRef.current.getDuration());
        SetPlayingStatus({...playingStatus, currentPlaying: playerRef.current.getCurrentTime()});
        // ApiCall.createActivity(getLoggedinUserData.id,'video_play_at', 'Task', object, task.id, play_pause_time);
        // setSeekCheck({play: true, buffer: false, pause: false});
        SetReactControl({...reactControl, playing: true });
        SetPlayingPath(playingPath => [...playingPath, 'play']);
      // }
    // }, 2000);
    // return () => clearTimeout(timer);
  }
  const handlePause = () => {
    let object = `${trainingData.name} is paused at ${format(playerRef.current.getCurrentTime())} / ${format(playerRef.current.getDuration())}`;
    const play_pause_time = format(playerRef.current.getCurrentTime()) + '/' + format(playerRef.current.getDuration());
    // console.log('ref', format(playerRef.current.getCurrentTime()));
    // SetPlayingStatus({...playingStatus,lastPlayed: playerRef.current.getCurrentTime()});
    // ApiCall.createActivity(getLoggedinUserData.id,'video_pause_at', 'Task', object, task.id,play_pause_time);
    // setSeekCheck({play: false, buffer: false, pause: true});
    SetReactControl({...reactControl, playing: false });
    SetPlayingPath(playingPath => [...playingPath, 'pause']);
  }
  const handleProgress = (st) => {
    // console.log('inprogress:', playingPath);
    
    // console.log('onProgress', playingStatus)
    // We only want to update time slider if we are not currently seeking
      reactControl.seeking = false;
      // reactControl.forward = false;
      // reactControl.backward = false;
    if (!reactControl.seeking) {
      // this.setState(state)
      // if(playingStatus.lastPlayed !== playingStatus.currentPlaying) {
      //   console.log("hello");
      //   if(diff > 1) {
      //     reactControl.seeking = true;
      //     reactControl.forward = true;
      //     const statement = 'forwarded from '+ format(playingStatus.lastPlayed) +' to '+ format(playingStatus.currentPlaying)
      //     console.log(statement);
      //   }
      //   if(diff < 0) {
      //     reactControl.seeking = true;
      //     reactControl.backward = true;
      //     const statement = 'seeking back to '+ format(playingStatus.currentPlaying) +' from '+ format(playingStatus.lastPlayed)
      //     console.log(statement);
      //   }
      // }
      // console.log(playingPath, playingPath.length);
      const playFlag = playerRef.current.props.playing;
      // console.log("playing:", forwardseek);
      if(playingPath.indexOf('pause') === 0 && playingPath.indexOf('buffer') === 1){
        // SetPlayingStatus({...playingStatus, currentPlaying: st.playedSeconds});
        setSeekCheck({play: false, buffer: false, pause: false});
        // console.log('seeking', true);
        forwardseek = true;
        setSeeking(true);
      } else if (playingPath.indexOf('pause') < 0 && playingPath.indexOf('play') < 0 && playFlag === false){
        // console.log('seeking', true);
        // SetPlayingStatus({...playingStatus, currentPlaying: st.playedSeconds});
        setSeekCheck({play: false, buffer: false, pause: false});
        setSeeking(true);
        forwardseek = false;
      } else if (playingPath.indexOf('pause') === 0 && playingPath.length === 2 ) {
        // SetPlayingStatus({...playingStatus, currentPlaying: st.playedSeconds});
        forwardseek = false;
        setSeekCheck({play: false, buffer: false, pause: true});
        setSeeking(false);
      } else if (playingPath.indexOf('play') > -1 && forwardseek === false) {
        // SetPlayingStatus({...playingStatus, lastPlayed: st.playedSeconds, currentPlaying: st.playedSeconds});
        setSeekCheck({play: true, buffer: false, pause: false});
        setSeeking(false);
      }
      if(playFlag === true){
        // console.log("play----");
        SetPlayingStatus({...playingStatus, lastPlayed: st.playedSeconds, currentPlaying: st.playedSeconds});
      } else if(playFlag === false) {
        // console.log("pause----");
        SetPlayingStatus({...playingStatus, currentPlaying: st.playedSeconds});
      }
      SetReactControl({...reactControl, ...st});
    }
    SetPlayingPath([]);
  }
  const handleBuffer = () => {
    // console.log("buffer");
    SetPlayingPath(playingPath => [...playingPath, 'buffer']);
    // setSeekCheck({...seekCheck, buffer: true});
  }
  useEffect(() => {
    const diff = playingStatus.currentPlaying - playingStatus.lastPlayed;
    console.log("d", seekCheck, seeking );
    if(seekCheck.play === true && seeking === false) {
      const object = `Played at ${format(playerRef.current.getCurrentTime())} / ${format(playerRef.current.getDuration())}`;
      const play_pause_time = format(playerRef.current.getCurrentTime()) + '/' + format(playerRef.current.getDuration());
      ApiCall.createActivity(getLoggedinUserData.id,'video_play_at', 'PublicTraining', object, trainingData.id,play_pause_time);
      // console.log('played at ', play_pause_time);
    }

    if(seekCheck.pause === true && seeking === false) {
      const object = `Paused at ${format(playerRef.current.getCurrentTime())} / ${format(playerRef.current.getDuration())}`;
      const play_pause_time = format(playerRef.current.getCurrentTime()) + '/' + format(playerRef.current.getDuration());
      ApiCall.createActivity(getLoggedinUserData.id,'video_pause_at', 'PublicTraining', object, trainingData.id,play_pause_time);
      // console.log('paused at ', play_pause_time);
    }
  }, [seekCheck, seeking]);
  useEffect(() => {
    if (!reactControl.seeking) {
    const diff = playingStatus.currentPlaying - playingStatus.lastPlayed;
    console.log(diff);
    if(playingStatus.lastPlayed !== playingStatus.currentPlaying) {
      if(diff > 1) {
        // seekCheck.seeking = true;
        // reactControl.forward = true;
        const object = 'Forwarded from '+ format(playingStatus.lastPlayed) +' to '+ format(playingStatus.currentPlaying);
        SetPlayingStatus({...playingStatus, lastPlayed: playingStatus.currentPlaying});
        const play_pause_time = format(playingStatus.lastPlayed) + '/' + format(playingStatus.currentPlaying) + '/' + format(playerRef.current.getDuration());
        ApiCall.createActivity(getLoggedinUserData.id,'video_forward_at', 'PublicTraining', object, trainingData.id,play_pause_time);
      } else if(diff < 0) {
        // reactControl.backward = true;
        // const statement = 'seeking back to '+ format(playingStatus.currentPlaying) +' from '+ format(playingStatus.lastPlayed);
        SetPlayingStatus({...playingStatus, lastPlayed: playingStatus.currentPlaying});
        const object = 'Seek back to '+ format(playingStatus.currentPlaying) +' from '+ format(playingStatus.lastPlayed);
        SetPlayingStatus({...playingStatus, lastPlayed: playingStatus.currentPlaying});
        const play_pause_time = format(playingStatus.lastPlayed) + '/' + format(playingStatus.currentPlaying) + '/' + format(playerRef.current.getDuration());
        ApiCall.createActivity(getLoggedinUserData.id,'video_backward_at', 'PublicTraining', object, trainingData.id,play_pause_time);
      } else {
        // console.log('else',diff);
        setSeeking(false);
      }
    }
  }
  }, [playingStatus]);

  const handleDuration = (duration) => {
    SetReactControl({...reactControl, duration: duration });
    if(isPremiere){
      var premT = moment(premTime);
      var difference = moment.duration(moment().diff((premT)));
      var seconds = difference.asSeconds();
      if(seconds > duration){
        setPremPlay(false);
        setExpMsg('This Link is Expired. Video time was set at: '+moment(premTime).format('DD/MM/yyy hh:mm A'));
        // SetReactControl({...reactControl, playing: false });
      }else{
        // const Plr = playerRef.current;
        // console.log(Plr);
        playerObj.seekTo(seconds, 'seconds');
        setPremPlay(true);
      }
    }
  }
  // const handleStateChange = () => {
  //   console.log("state changed");
  // }
  const handleEnded = () => {
    // console.log('onEnded', format(reactControl.duration * reactControl.played));
    let object = `${trainingData.name} is ended at ${format(playerRef.current.getCurrentTime())} / ${format(playerRef.current.getDuration())}`;
    const play_pause_time = format(playerRef.current.getCurrentTime()) + '/' + format(playerRef.current.getDuration());
    ApiCall.createActivity(getLoggedinUserData.id,'video_ended_at', 'PublicTraining', object, trainingData.id, play_pause_time);
    SetReactControl({...reactControl, playing: reactControl.loop });
  }
  const handleToggleMuted = () => {
    SetReactControl({...reactControl, muted: !reactControl.muted });
  }
  //End of tracking code
  let { trainingId } = useParams();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // const handleClickFullscreen = () => {
  //   screenfull.request(findDOMNode(playerRef))
  // }
  const [fullscreenMode, setFullscreenMode] = useState(false);

  const onReady =  (player) => {
    // console.log("loading status", player.getDuration());
    setPlayerObj(player);
    // if(isPremiere){
    //   var premT = moment(premTime);
    //   var duration = moment.duration(moment().diff((premT)));
    //   var seconds = duration.asSeconds();
    //   console.log(seconds,  player.getDuration());
    //   if(seconds > player.getDuration()){
    //     setPremPlay(false);
    //     setExpMsg('This Link is Expired. Video time was set at: '+moment(premTime).format('DD/MM/yyy hh:mm A'));
    //     // SetReactControl({...reactControl, playing: false });
    //   }else{
    //     player.seekTo(seconds, 'seconds');
    //     setPremPlay(true);
    //   }
    // }
  }

  // const onDuration = (player) => {
  //   console.log("duration", player.getDuration());
  // }
  const handleCustomFullScreen = () => {
    if (!fullscreenMode){
      // console.log("hdghfhghfshfh");
        findDOMNode(playerRef.current).requestFullscreen().catch(
            (err) => 
            {toast.error("Could not activate full-screen mode :(")}
        );
    }
  }
  const onStart = () => {
    //console.log("playing", reactControl);
    //console.log(moment(premTime).format('hh:mm A'), moment().format('hh:mm A'));

      // if (!fullscreenMode){
      //   // console.log("hdghfhghfshfh");
      //     findDOMNode(playerRef.current).requestFullscreen().catch(
      //         (err) => 
      //         {toast.error("Could not activate full-screen mode :(")}
      //     );
      // }
  }
  const handlePlayPause = () => {
    // console.log("clicked", location);
    SetReactControl({...reactControl, playing: !reactControl.playing });
  }
  const [trainingData, SetTrainingData] = useStateRef({});
  const [isPremiere, SetisPremiere] = useStateRef(false);
  const [pageClosed, setPageClosed] = useStateRef(false);
  const [premTime, SetPremTime] = useStateRef('');
  const [participantList, SetParticipantList] = useStateRef([]);
  const [room, setRoom] = useState("");

  const options1 = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthToken}`,
    }
  };
  // useEffect(()=> {
  //   // console.log(userContext.userData);
  // }, [userContext.userData]);

  useEffect(()=> {
    // console.log('playing',reactControl);
  }, [reactControl.playing]);

  useEffect(()=> {
    // console.log('premplay',premPlay);
    if(premPlay){
      SetReactControl({...reactControl, playing: true });
    }
  }, [premPlay]);
  
  /*code for premiere*/
  useEffect(()=> {
    // const prem_time = searchParams.get('prem_time');
    // if(isPremiere){
    //   const iframe = document.querySelector('iframe');
    //   const player = new ReactPlayer(iframe);
    //   player.loadVideo(123456).then(() => {
    //     player.ready().then(() => {
    //       player.getDuration().then((data) => console.log(data));
    //     }).catch((err) => console.log(err));
    //   })
    // }
  }, [isPremiere]);
  const handleBeforeUnload = (e) => {
    // Perform cleanup or logging out here
    e.preventDefault();
    e.returnValue = '';
    // const dataObj = {trainingId, viewerId: getLoggedinUserData.id, status: 'Offline' };
    socket.emit("leftTraining", {trainingId, viewerId: getLoggedinUserData.id, status: 'Offline' });
    setPageClosed(true);
  };
  
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    // socket.emit('username', userContext.userData.fname+' '+userContext.userData.lname);
    document.addEventListener('contextmenu', (e) => {
      // e.preventDefault();
    });
    
    const pageType = searchParams.get('type')?searchParams.get('type'):null;
    var decodedType = '';
    if(pageType){
      //decodedType = atob(pageType); // decode the string
      decodedType = inLocalStorageJwt.decodeWithAES(pageType, secretKey);
      const query = new URLSearchParams(decodedType);
      const premiere = query.get("premiere");
      if(premiere=='true'){
        // SetSocket(io.connect(process.env.REACT_APP_SERVER_BASE_URL, {transports: ['websocket']}));
        SetisPremiere(true);
        SetPremTime(query.get('prem_time'));
        var premT = moment(query.get('prem_time'));
        var duration = moment.duration(moment().diff((premT)));
        var seconds = duration.asSeconds();
        // console.log(seconds);
        if(seconds > 0){
          setPremPlay(true);
        }
      }else if(premiere !== null){
        // console.log("here", premiere);
        const explink = query.get("explink");
        const currentTime = new Date();
        if(currentTime.toISOString() > explink){
          setLinkExpired(true);
        }
        setPremPlay(false);
        setPublnkPlay(true);
        SetisPremiere(false);
      }
    }
    // console.log(searchParams.get('type'), decodedType);
    
    async function getTraining() {
      if (trainingId) {
        SetTrainingData({ id: trainingId });
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/trainings/view-public/${trainingId}`, options1)
          .then(async (res) => {
            // console.log(res.data.training);
            setLoading(false);
            if(res.data.training.visibility==='private'){
              setPrivate(true);
            }
            SetTrainingData(res.data.training);
            const tdata = res.data.training;
            document.title= 'Training: '+ tdata.name;
            // console.log(trainingData);
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      }
    }
    async function getPremiere(trainingId) {
      if (trainingId) {
        SetTrainingData({ id: trainingId });
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/premieres/get-detail/${trainingId}`, options1)
          .then(async (res) => {
            if(res.data.premiere){
              SetPremiereData(res.data.premiere);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    async function getSubscription(trainingId) {
      if (trainingId) {
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/users/get-user-by-training/${trainingId}`, options1)
          .then(async (res) => {
            if(res.data.subscription){
              SetSubscriptionData(res.data.subscription);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    getTraining();
    getPremiere(trainingId);
    getSubscription(trainingId);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []); // Or [] if effect doesn't need props or state

  useEffect(() => {
    const isEmpty = Object.keys(subscriptionData).length === 0  
    if(!isEmpty && isPremiere){
      const subData = subscriptionData[0];
      const plan = subData.plan;
      // const expDate = plan.endDate;
      const premiereLmt = plan.features[0].premiere;
      SetparticipantsLmt(premiereLmt);
      // console.log(subscriptionData[0], premiereLmt);
    }
    if(!isEmpty){
      const planExp = new Date(subscriptionData[0].endDate);
      const currentTime = new Date();
      if(currentTime > planExp){
        setLoading(false);
        setPlanExpired(true);
      }
    }
  }, [subscriptionData]);

  useEffect(() => {
    // console.log('isPremiere',isPremiere);
    if(isPremiere){
    // console.log("change detected in socket");
    // // client-side
    // socket.on("connect", () => {
    //   console.log(socket.id); // x8WIv7-mJelg7on_ALbx
    // });

    // socket.on("disconnect", () => {
    //   console.log(socket.id); // undefined
    // });
    
    socket.on("roomIsFull", (premId) => {
      SetisPremListFull(true);
    });

    socket.on("participantsList", (partList) => {
    // console.log("change detected in socket participantsList");
      const isEmpty = Object.keys(partList).length === 0  
      if(!isEmpty){
        // console.log("premieres", partList);
        SetParticipantList(partList);
      }
    })
    }else{
      // const dataObj = {trainingId, viewerId: getLoggedinUserData.id, status: 'Online' };
      socket.emit("joinTraining", {trainingId, viewerId: getLoggedinUserData.id, status: 'Online' });
    }
  }, [socket, isPremiere]);

  useEffect(() => {
    const isEmpty = Object.keys(premiereData).length === 0  
    if(!isEmpty && isPremiere){
      const userObj = {premiereId:premiereData.id,name: userContext.userData.name, uid: userContext.userData.id, playing:reactControl.playing, muted:reactControl.muted, PageVisible: isWindowInFocus}
      socket.emit("join_premiere", {premiereId:premiereData.id, roomLimit: participantsLmt });
      // console.log("isWindowInFocus", userObj)
      socket.emit('participantAdd', userObj);
    }
  }, [premiereData]);
  
  // useEffect(() => {
  //   // console.log(participantsLmt, participantList.length, isPremListFull);
  //   if(participantList.length > 0 && participantsLmt < participantList.length){
  //     SetisPremListFull(true);
  //   }
  // }, [participantList]);

  useEffect(() => {
    SetScreenWidth(window.innerWidth);
  }, [window]);

  useEffect(() => {
    if(isPremiere){
      // console.log(reactControl.playing);
      const userObj = {premiereId:premiereData.id,name: userContext.userData.name, uid: userContext.userData.id, playing:reactControl.playing, muted:reactControl.muted, PageVisible: isWindowInFocus};
      socket.emit('participantUpdated', userObj);
    }
  }, [reactControl.playing]);

  useEffect(() => {
    if(isPremiere){
      // console.log(reactControl.muted);
      const userObj = {premiereId:premiereData.id,name: userContext.userData.name, uid: userContext.userData.id, playing:reactControl.playing, muted:reactControl.muted, PageVisible: isWindowInFocus};
      socket.emit('participantUpdated', userObj);
    }
  }, [reactControl.muted]);

  useEffect(() => {
    const isEmptyUdata = Object.keys(userContext.userData).length === 0  
    if(!isEmptyUdata && !userContext.userData.name){
      window.location.reload();
    }
  }, [userContext.userData]);

  async function setShowAuth(booln, type) {
      SetShowLogin(booln);
      SetShowRegister(booln);
  };
  const trainingContextVal = {
    setShowAuth
  };
  const listentoWindow = (isVisible) => {
    // console.log("isVisible", isVisible);
    if(isPremiere){
    const userObj = {premiereId:premiereData.id,name: userContext.userData.name, uid: userContext.userData.id, playing:reactControl.playing, muted:reactControl.muted, PageVisible: isVisible};
    socket.emit('participantUpdated', userObj);
    setIsWindowInFocus(isVisible)
    }

    if(!isVisible){
      // console.log('background');
      // const dataObj = {trainingId, viewerId: getLoggedinUserData.id, status: 'Background' };
      if(!pageClosed){
        socket.emit("leftTraining", {trainingId, viewerId: getLoggedinUserData.id, status: 'Background' });
      }
    }else{
      // const dataObj = {trainingId, viewerId: getLoggedinUserData.id, status: 'Online' };
      socket.emit("joinTraining", {trainingId, viewerId: getLoggedinUserData.id, status: 'Online' });
    }
  }
  return (
    <PageVisibility onChange={listentoWindow}>
    <TrainingContext.Provider value={trainingContextVal}>
    {!loading && !isPremListFull && trainingData && trainingData.name && !planExpired && !linkExpired ?
        (
        <Card className={classes.videoCard}>
          <>
            {/* <Box p={2}>
              <Wifi />
            </Box> */}
            {(userContext.userData && !userContext.userData.name? (
              <div>
              {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                Open form dialog
              </Button> */}
              <Dialog open={userContext.userData && !userContext.userData.name?true:false} onClose={handleClose} aria-labelledby="form-dialog-title">
                {/* <DialogTitle id="form-dialog-title">Verify</DialogTitle> */}
                      <DialogContent>
                        <DialogContentText>
                          <b>To watch this video, you need to verify first</b>
                        </DialogContentText>
                        {
                          !showRegister && !showLogin &&
                          (
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Guest redirectToPublic={location.pathname+location.search}/>
                              </Grid>
                              <Grid item xs={12}>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  style={{'height':'50px', 'width':'100%', 'backgroundColor': '#ff9900'}}
                                  onClick={() => {
                                    SetShowRegister(true);
                                  }}
                                >
                                New User Registration
                                </Button>
                              </Grid>
                              <Grid item xs={12}>
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  style={{'height': '50px', 'width':'100%', 'backgroundColor': '#44A048'}}
                                  onClick={() => {
                                    SetShowLogin(true);
                                  }}
                                >
                                Verified User (login with Email + password)
                                </Button>
                              </Grid>
                            </Grid>
                          )}
                        {
                          showRegister? <RegisterView redirectToPublic={location.pathname+location.search} /> : ''
                        }
                        {
                          showLogin? <LoginView redirectToPublic={location.pathname+location.search} /> : ''
                        }
                      </DialogContent>
                      {/* <DialogActions>
                        <Button type="submit" color="primary" disabled={isSubmitting}>
                          {isSubmitting? 'Loading..' : 'Verify'}
                        </Button>
                      </DialogActions> */}
              </Dialog>
            </div>
            ):'')}
            <CardHeader
              avatar={
                  (
                  <Avatar aria-label="recipe" className={classes.avatar}>
                      T
                  </Avatar>
              )
              }
              action={
                  (
                  // <IconButton aria-label="settings">
                  //     {/* <MoreVertIcon /> */}
                  // </IconButton>
                  !publnkPlay && <ParticipantList participantList={participantList}/>
                  )
              }
              title={<b>{trainingData && trainingData.name + ' by ' + trainingData.trainer}</b>}
              subheader={'Topics: '+trainingData && trainingData.topic}
            />
            <div className="Iframecontainer">
              <div
                onClick={handlePlayPause}
              >
              <div
                  className="player-wrapper"
                  style={{
                    pointerEvents: 'none',
                    // position: isPremiere && 'absolute',
                    // border: '1px solid black',
                    height: screenWidth>600? '700px': '365px'
                   }}
                  // style={{
                  // border: '1px solid black',
                  // }}
              >
                {
                  (premPlay || publnkPlay) && (userContext.userData && userContext.userData.name?true:false)  && (
                  <>
                  <ReactPlayer
                    ref={playerRef}
                    width="100%"
                    height={screenWidth>600? '700px': '365px'}
                    onContextMenu={(e) => { e.preventDefault(); }}
                    // controls={!false}
                    url={trainingData && trainingData.video_link}
                    // config={{ youtube: { playerVars: { disablekb: 1, showinfo: 0 } } }}
                    onSeek={(e) => console.log('onSeek', e)}
                    controls={false}
                    playing={reactControl.playing}
                    muted={reactControl.muted}
                    volume={reactControl.volume}
                    onPlay={handlePlay}
                    // onPlay={(e) => { console.log('play', e); }}
                    onPause={handlePause}
                    onBuffer={handleBuffer}
                    onProgress={handleProgress}
                    onDuration={handleDuration}
                    onStart={onStart}
                    onEnded={handleEnded}
                    onReady={onReady}
                    // onDuration={onDuration}
                    // played={format(reactControl.duration * reactControl.played)}
                    onClick={(e)=> {console.log('click');}}
                    // onSeek={e => console.log('onSeek', e)}
                    // onStateChange={handleStateChange}
                    // playbackQuality={suggestedQuality}
                    // muted={reactControl.muted}
                    config={{
                      youtube: {
                        playerVars: {
                          disablekb: 1,
                          showinfo: 0,
                          showinfo: 0,
                          enablejsapi:0,
                          origin: process.env.REACT_APP_BASE_URL 
                        },
                        events: {
                          onStateChange: () => {console.log('hhhh')}
                        }
                      }
                    }}
                    // playIcon={<button>Play</button>}
                    // light="https://i.stack.imgur.com/zw9Iz.png"
                  />
                  </>
                  )}
                  {!publnkPlay &&
                  <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    // justify="center"
                    style={{ minHeight: '60vh' }}
                  >
                    <Grid item xs={6}>
                      <Countdown
                        onComplete={(e) => {setPremPlay(true)}}
                        // onTick={(e) => { console.log(e); }}
                        ref = {countRef}
                        date={new Date(premTime)}
                        renderer={renderer}
                      />
                    </Grid>   
                  </Grid>
                   }
              </div>
              </div>
              {publnkPlay && 
                  (<Soundbar
                    volumebar={volumebar}
                    handleMute={handleMute}
                    handlePlay={handlePlay}
                    handlePause={handlePause}
                    handleCustomFullScreen={handleCustomFullScreen}
                    playingStatus={playingStatus}
                    duration={reactControl.duration}
                    handleBuffer={handleBuffer}
                    seekToSeconds={seekToSeconds}
                    reactControl={reactControl}
                  />
                )}
              {
                premPlay && !publnkPlay && (userContext.userData && userContext.userData.name?true:false)  && (
                  <>
                  <Grid
                    container
                    spacing={1}
                    direction="column"
                    alignItems="center"
                    // justify="center"
                    style={{ minHeight: '10vh', paddingTop:'3px', backgroundColor: 'black', color:'white' }}
                  >
                    <Grid item xs={12}>
                      <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1, color:'white'  }}>
                        <IconButton className={classes.customControls} onClick={handlePlayPause}>
                          {/* {reactControl.playing? 'Pause':'Play'} */}
                          {reactControl.playing? <PauseIcon style={{ fontSize: 30 }} />:''}
                          {!reactControl.playing? <PlayArrowIcon style={{ fontSize: 30 }} />:''}
                        </IconButton>
                        <IconButton className={classes.customControls} aria-label="play/pause" onClick={handleToggleMuted}>
                          {reactControl.muted && 
                            (<VolumeOffIcon style={{ fontSize: 30 }}></VolumeOffIcon>
                          )}
                          {!reactControl.muted && 
                          <VolumeUpIcon style={{ fontSize: 30 }}></VolumeUpIcon>
                          }
                        </IconButton>
                        <IconButton className={classes.customControls} aria-label="next" onClick={handleCustomFullScreen}>
                          <FullscreenIcon style={{ fontSize: 30 }}></FullscreenIcon>
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                </>
                )
              }
            </div>
            
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  {trainingData && trainingData.notes}
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                {/* <IconButton aria-label="add to favorites">
                <FavoriteIcon />
                </IconButton>
                <IconButton aria-label="share">
                <ShareIcon />
                </IconButton> */}
                <IconButton
                className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
                >
                <ExpandMoreIcon />
                </IconButton>
            </CardActions>
            <Collapse style={{minHeight:'200px'}} in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                  <div>
                    <span>Update Your Basic info here.</span> <br />
                    <Link to='app/dashboard'>Go to Home</Link>
                  </div>    
                  {/* <Typography paragraph>Method:</Typography>
                  <Typography paragraph>
                      Heat 1/2 cup of the broth in a pot until simmering, add saffron and set aside for 10
                      minutes.
                  </Typography>
                  <Typography paragraph>
                      Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over medium-high
                      heat. Add chicken, shrimp and chorizo, and cook, stirring occasionally until lightly
                      browned, 6 to 8 minutes. Transfer shrimp to a large plate and set aside, leaving chicken
                      and chorizo in the pan. Add pimentón, bay leaves, garlic, tomatoes, onion, salt and
                      pepper, and cook, stirring often until thickened and fragrant, about 10 minutes. Add
                      saffron broth and remaining 4 1/2 cups chicken broth; bring to a boil.
                  </Typography> */}
                </CardContent>
            </Collapse>
        </>
        </Card>
        ):(
          <Box
            className={classes.videoCard}
            style={{textAlign:'center',marginTop:'200px'}}
          >
          {planExpired &&
            (
            <div>
              <span>This link is expired.</span> <br />
              <Link to='/'>Go to Home</Link>
            </div>
          )}
          {linkExpired &&
            (
            <div>
              <span>This link is expired. Kindly get the new one.</span> <br />
              <Link to='/'>Go to Home</Link>
            </div>
          )}
          {isprivate?(
            <div>
              <span>Content is private</span> <br />
              <Link to='/'>Go to Home</Link>
            </div>
          ):(
            isPremListFull?(
            <div>
              <span>Maximum Users Reached for the session. Participant List is full.</span> <br />
              <Link to='/'>Go to Home</Link>
            </div>):
            (loading ? <Loader type="Circles" color="#00BFFF" height={80} width={80}/>: '')
          )}
          </Box>
        )}
    </TrainingContext.Provider>
    </PageVisibility>
  );
}
